<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="customerReportList && customerReportList.length > 0 && showHeaders.length > 0 ? '475' : ''"
              :style="{maxHeight: !customerReportList.length ? '370px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="customerReportList"
              :loading="isProgressCircular"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.customer_opening_date`]="{ item }">
                <span>{{profileById.date_format ? formatDateDDMMYYYY(item.customer_opening_date) : parseDateYYYYMMDD(item.customer_opening_date)}}</span>
              </template>
              <template v-slot:[`item.customer_end_date`]="{ item }">
                <span>{{profileById.date_format ? formatDateDDMMYYYY(item.customer_end_date) : parseDateYYYYMMDD(item.customer_end_date)}}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("customer.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="mx-1 mb-1" :disabled="customerReportList.length" :model="customerReportList" :payload="payload" type="CustomerReportPDF"/>
                  <ExcelAndResetFilterCommon :data="customerReportList.length" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="3">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="selectedCustomerGroup"
                        :items="customerGroupValue"
                        item-text="description"
                        item-value="id"
                        clearable
                        flat
                        @input="buildUrl"
                        return-object
                        :label="$t('customer.customerGroup')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedRegion"
                        :items="regionValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('customer.area')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedStatus"
                        :items="statusValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('customer.status')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedPayment"
                        :items="termsOfPaymentValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('customer.field.termsOfPayment')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon @reset="reset" @paginate="paginate(false)" type="Customer"/>
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("customer.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("customer.selectFields") }}
                                  </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t('customer.update') }}</v-btn>
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px;">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                              :label="$t('customer.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{$t('customer.selectAll')}}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("customer.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{ customerReportList && customerReportList.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="pageCount"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from 'export-from-json';
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";

export default {
  name: "CustomerDebitReport",
  props: ['type'],
  components: {
    ListSkeleton,
    HtmlToPdf,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      customerLimit: 10,
      selectedCustomerGroup: null,
      selectedRegion: null,
      customerGroupValue: [],
      selectedPayment: null,
      regionValue: [],
      listSkeleton: false,
      selectedStatus: null,
      statusValue: [],
      termsOfPaymentValue: [],
      dialog: false,
      selectedHeaders: [],
      selectFields: false,
      search: this.$route.query.search || "",
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      numberOfRows: "",
      custom: {id: 111111111111111, description: this.$t('invoice.empty')},
      isProgressCircular: false,
    };
  },
  computed: {
    params() {
      return {
        search: this.search,
        customer_group_id: this.selectedCustomerGroup?.id,
        region_group_id: this.selectedRegion?.id,
        customer_id: this.selectedCustomer?.id,
        selected_status_id: this.selectedStatus?.id,
        selected_payment_id: this.selectedPayment?.id
      };
    },
    payload() {
      return {
        customer_group_id: this.selectedCustomerGroup?.description,
        region_group_id: this.selectedRegion?.description,
        selected_status_id: this.selectedStatus?.description,
        selected_payment_id: this.selectedPayment?.description
      }
    },
    selectAllHeaders() {
      return this.selectedHeaders.length === this.headers.length
    },
    ...mapGetters({
      customerReportList: "customer/customerReportList",
      locale: "locale",
      showHeaders: "customerReport/showHeaders",
      accessRight: 'accessRight',
      pageChange: "customerReport/pageChange",
      profileById: 'profile/profileById',
      rowIndex: "customer/rowIndex",
    }),
    headers() {
      return [
        { text: this.$t('customer.id'), value: "id", width: "130px", align: "start", class: "px-1", sortable: true },
        { text: this.$t('customer.customerName'), value: "name_1", width: "180px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.email'), value: "email", width: "200px", align: "start", class: "px-2", sortable: true },
        { text: this.$t("customer.field.emailInvoice"), value: "email_invoice", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.group"), value: "group.description", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.status"), value: "status_id", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.termsOfPayment"), value: "paymentTerms.description", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.customerOpeningDate"), value: "customer_opening_date", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.area"), value: "region_id", width: "140px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.address1'), value: "address_1", width: "180px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.postalCode1'), value: "postal_code_1", width: "110px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.pobox1'), value: "po_box_1", width: "110px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.town1'), value: "town_1", width: "160px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.state"), value: "state", width: "140px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.country'), value: "country", width: "110px", class: "px-2", sortable: true },
        { text: this.$t('customer.fax'), value: "fax", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.customerEndDate"), value: "customer_end_date", width: "140px", class: "px-2", sortable: true },      
        { text: this.$t("customer.customerName2"), value: "name_2", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.address2"), value: "address_2", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.postalCode2"), value: "postal_code_2", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.pobox2"), value: "po_box_2", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.town2"), value: "town_2", width: "140px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.vatCharge'), value: "vat_charge", width: "110px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.site"), value: "site", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.authorizedDealerNo"), value: "authorized_dealer_no", width: "140px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.bookKipingNo'), value: "book_kiping_no", width: "110px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.neighborhood"), value: "neighborhood", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.floor"), value: "floor", width: "80px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.customerOrderNoRequired"), value: "customer_order_no_compulsory", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.proposalNote"), value: "proposal_note", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.deliveryNote"), value: "delivery_note", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.orderNote"), value: "order_note", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.invoiceNote"), value: "invoice_note", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.warningNote"), value: "warning_note", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.generalRemarks"), value: "general_remarks", width: "140px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix1"), value: "country_prefix_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea1"), value: "area_prefix_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.cellular1"), value: "cellular_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.extension0"), value: "extension_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.phones0"), value: "phone_1", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix2"), value: "country_prefix_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea2"), value: "area_prefix_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.cellular2"), value: "cellular_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.extension1"), value: "extension_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.phones1"), value: "phone_2", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix3"), value: "country_prefix_3", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea3"), value: "area_prefix_3", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.extension2"), value: "extension_3", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.phones2"), value: "phone_3", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix4"), value: "country_prefix_4", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea4"), value: "area_prefix_4", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix5"), value: "country_prefix_5", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea5"), value: "area_prefix_5", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.countryPrefix0"), value: "country_prefix_6", width: "100px", class: "px-2", sortable: true },
        { text: this.$t("customer.field.dialingArea0"), value: "area_prefix_6", width: "100px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.createdAt'), value: "created_at", width: "140px", class: "px-2", sortable: true },
        { text: this.$t('customer.field.updatedAt'), value: "updated_at", width: "140px", class: "px-2", sortable: true },
      ];
    },
    empty() {
      return this.$t('invoice.empty')
    }
  },
  created() {
    if (this.showHeaders.length == 0) {
      this.$store.commit('customerReport/SHOW_HEADERS', this.headers)
    }
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("customerReport/SHOW_PAGE_CHANGE", {page: this.pagination.page, limit: this.pagination.itemsPerPage})
  },
  destroyed() {
    if (!this.$route.params.customer_id) {
      this.$store.commit('customer/SET_CUSTOMER_REPORT', [])
    }
  },
  watch: {
    empty() {
      this.custom.description = this.empty
    },
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
        }
      },
      deep: true
    },
    type: {
      handler() {
        this.search = '';
      }
    },
    '$route.query': {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length) 
        this.search = this.$route.query.search || ""
      }
    },
    selectedHeaders() {
      if (this.selectedHeaders.length === this.headers.length) {
        this.selectFields = true
      } else {
        this.selectFields = false
      }
    },
    locale() {
      this.updateHeader();
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.selectedHeaders = this.showHeaders
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.dispatch("customer/GetCustomer");
    getAuxiliaryZtableValueByName(2).then((tableCustomerValue) => {
      if (Object.keys(tableCustomerValue).length > 0) {
        this.customerGroupValue = tableCustomerValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.customerGroupValue = [this.custom, ...this.customerGroupValue]
      }
    });
    getAuxiliaryZtableValueByName(6).then((tableRegionNameValue) => {
      if (Object.keys(tableRegionNameValue).length > 0) {
        this.regionValue = tableRegionNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.regionValue = [this.custom, ...this.regionValue]
      }
    });
    getAuxiliaryZtableValueByName(5).then((statusTableNameValue) => {
      if (Object.keys(statusTableNameValue).length > 0) {
        this.statusValue = statusTableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.statusValue = [this.custom, ...this.statusValue]
      }
    });
    getAuxiliaryZtableValueByName(7).then((termsOfPaymentTableNameValue) => {
      if (Object.keys(termsOfPaymentTableNameValue).length > 0) {
        this.termsOfPaymentValue = termsOfPaymentTableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.termsOfPaymentValue = [this.custom, ...this.termsOfPaymentValue]
      }
    });
    let rowIndex = this.$store.state.customer.rowIndex;
    if (rowIndex && this.customerReportList.length > 0) {
      this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" })
    }
    this.listSkeleton = false;
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    doubleClick(event, {item}) {
      const path = 'customer/editCustomer/' + item.id;
      this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      this.$store.commit('customer/SET_ROWINDEX', item.id);
    },
    newPagination(val) {
      this.pagination = val
      this.numberOfRows = val.itemsLength
    },
    updateHeader() {
      this.$store.commit('customerReport/SHOW_HEADERS', this.headers.filter(s => this.selectedHeaders.some(d => d.value === s.value)))
      this.selectedHeaders = this.showHeaders
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllHeaders) {
          this.selectedHeaders = []
        } else {
          this.selectedHeaders = this.headers.slice()
        }
      })
    },
    reset() {
      this.search = null;
      this.$store.commit('customer/SET_CUSTOMER_REPORT', [])
      this.page = 1;
      this.limit = 10;
      this.limitData = this.limit;
    },
    resetFilters() {
      this.sortByValue = null;
      this.selectedCustomer = null;
      this.selectedCustomerGroup = null;
      this.selectedRegion = null;
      this.selectedStatus = null;
      this.selectedPayment = null;
      this.$store.commit("customer/SET_CUSTOMER_REPORT_QUERY", {});
      this.buildUrl();
    },
    downloadExcelFile() {
      let self = this;
      let data = self.customerReportList.map(o => Object.fromEntries(self.selectedHeaders.map(k => [k.text, k.value.includes('.')?
      o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
      data.forEach((el)=> {
        for (let x in el) {
          if (!el[x]) {
            el[x] = ''
          }}
      })
      exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "customerReport", exportType: exportFromJSON.types.xls})
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.search = this.search;
      obj.status = this.selectedStatus?.id;
      obj.custId = this.selectedCustomer?.id;
      obj.termsOfPaymentId = this.selectedPayment?.id;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.customerGroupId = this.selectedCustomerGroup?.id;
      obj.regionGroupId = this.selectedRegion?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "custId" && val === null) return acc;
        else if (key === "customerGroupId" && val === null) return acc;
        else if (key === "regionGroupId" && val === null) return acc;
        else if (key === 'termsOfPaymentId' && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        else if (key === "status" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("customer/SET_CUSTOMER_REPORT_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      const {
        customer_group_id,
        region_group_id,
        selected_status_id,
        selected_payment_id,
        customer_id,
      } = this.params;
      
      const payloadData = {
        where: customer_id ? "customer_id|" + customer_id : undefined,
        whereAnd: customer_group_id
          ? "group_id|" + customer_group_id
          : undefined,
        where_and: '',
        empty: customer_group_id ? "group_id|" + 'NULL' : undefined,
        empty_1: region_group_id ? "customers.region_id|" + 'NULL' : undefined,
        empty_2: selected_status_id ? "customers.status_id|" + 'NULL' : undefined,
        empty_3: selected_payment_id ? "customers.payment_terms_id|" + 'NULL' : undefined,
      }

      if (customer_group_id && customer_group_id === 111111111111111) {
        payloadData.empty, delete payloadData.whereAnd
      } else {
        payloadData.whereAnd, delete payloadData.empty
      }

      let where_and_prefix = ""
      let where_and_sufix = ""

      if (region_group_id && region_group_id !== 111111111111111) {
        where_and_prefix += "customers.region_id,",
        where_and_sufix += region_group_id + ','
        delete payloadData.empty_1
      }

      if (selected_status_id && selected_status_id !== 111111111111111) {
        where_and_prefix += "customers.status_id,",
        where_and_sufix += selected_status_id + ','
        delete payloadData.empty_2
      }

      if (selected_payment_id && selected_payment_id !== 111111111111111) {
        where_and_prefix += "customers.payment_terms_id,",
        where_and_sufix += selected_payment_id + ','
        delete payloadData.empty_3
      }

      where_and_prefix = where_and_prefix.replace(/.$/,"|")
      where_and_sufix = where_and_sufix.replace(/.$/,"")

      payloadData.where_and = where_and_prefix + where_and_sufix

      if ((region_group_id && region_group_id === 111111111111111) && (selected_status_id && selected_status_id === 111111111111111) && (selected_payment_id && selected_payment_id === 111111111111111)) {
        delete payloadData.where_and
      }

      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store.dispatch("customer/GetCustomerReport", payloadData).then(() => {
        this.isProgressCircular = false;
        this.$refs.search.focus();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>